/* eslint-disable no-undef */
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';

import * as appReducers from '../ducks';
import * as api from '../services/api';

let middleware = applyMiddleware(thunk.withExtraArgument(api));
const reducers = combineReducers({ ...appReducers, form: formReducer });

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined; // eslint-disable-line no-param-reassign
  }
  // Hard state change needed, because redux form doesnt clean up his shit
  if (action.type === 'VACANCY_FORM_RESET') {
    if (state.form && state.form.vacancylogging) {
      state.form.vacancylogging = undefined; // eslint-disable-line no-param-reassign
    }
  }
  return reducers(state, action);
};

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development' && typeof window.devToolsExtension === 'function') {
  middleware = compose(middleware, window.devToolsExtension());
}

export default createStore(rootReducer, middleware);
