import { navigate } from 'gatsby';

import { post } from '../services/api';
import createAction from '../services/createAction';

const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';
const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
const PASSWORD_SET_NEW_REQUEST = 'PASSWORD_SET_NEW_REQUEST';
const PASSWORD_SET_NEW_SUCCESS = 'PASSWORD_SET_NEW_SUCCESS';

const initialState = {
  error: null,
  mailSent: false,
  sendingMail: false,
  settingNewPassword: false,
  newPasswordSet: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type }) => {
  switch (type) {
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        sendingMail: true,
      };
    case PASSWORD_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        sendingMail: false,
        mailSent: true,
      };
    case PASSWORD_RESET_ERROR:
      return {
        ...state,
        sendingMail: false,
        settingNewPassword: false,
        error: 'Er is iets fout gegaan. Probeer het later opnieuw.',
      };
    case PASSWORD_SET_NEW_REQUEST:
      return {
        ...state,
        settingNewPassword: true,
      };
    case PASSWORD_SET_NEW_SUCCESS:
      return {
        ...state,
        settingNewPassword: false,
        newPasswordSet: true,
      };
    default:
      return state;
  }
};

export const passwordResetRequest = createAction(PASSWORD_RESET_REQUEST);
export const passwordResetEmailSuccess = createAction(PASSWORD_RESET_EMAIL_SUCCESS);
export const passwordResetError = createAction(PASSWORD_RESET_ERROR);

export const getPasswordResetLink = () => (dispatch, getState) => {
  // let people know we're doing something
  dispatch(passwordResetRequest());

  // Get the values from the state
  const { username } = getState().form.passwordResetForm.values;

  // GTFO if we don't have a username
  if (!username) {
    dispatch(passwordResetError());
    return false;
  }
  return post({
    path: '/user/forgot-password',
    body: { username },
    includeJwt: false,
  })
    .then(() => {
      dispatch(passwordResetEmailSuccess());
    })
    .catch(() => {
      dispatch(passwordResetError());
    });
};

export const settingNewPassword = createAction(PASSWORD_SET_NEW_REQUEST);
export const newPasswordSet = createAction(PASSWORD_SET_NEW_SUCCESS);

export const setNewPassword = () => (dispatch, getState) => {
  // let people know we're doing something
  dispatch(settingNewPassword());

  // Get the values from the state
  const { token, password } = getState().form.passwordResetForm.values;

  // GTFO if we don't have a token or a password
  if (!token || !password) {
    dispatch(passwordResetError());
    return false;
  }

  // gib paswort plox
  return post({
    path: '/user/reset-password',
    body: {
      confirmationToken: token,
      password,
    },
    includeJwt: false,
  })
    .then(() => {
      dispatch(newPasswordSet());

      if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-undef
        window.setTimeout(() => {
          navigate('/');
        }, 5000);
      }
    })
    .catch(() => {
      dispatch(passwordResetError());
    });
};
