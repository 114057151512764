import saveAs from 'file-saver';
import { navigate } from 'gatsby';
import { reset, SubmissionError } from 'redux-form';

import { getReadableViolationsFromResponse } from '../helpers/apiViolationHelpers';
import { convertConnectionToVacancyForm } from '../helpers/connectionHelpers';
import { routes } from '../helpers/routes';
import { mouldApiToForm, mouldFormToApi } from '../helpers/vacancyHelpers';
import { get, post } from '../services/api';
import createAction from '../services/createAction';
import stripString from '../services/stripString';

const VACANCY_SUBMIT_REQUEST = 'VACANCY_SUBMIT_REQUEST';
const VACANCY_SUBMIT_SUCCESS = 'VACANCY_SUBMIT_SUCCESS';
const VACANCY_UNSUBMIT_SUCCESS = 'VACANCY_UNSUBMIT_SUCCESS';
const VACANCY_SUBMIT_ERROR = 'VACANCY_SUBMIT_ERROR';

const VACANCY_FORM_RESET = 'VACANCY_FORM_RESET';

const VACANCY_GET_REQUEST = 'VACANCY_GET_REQUEST';
const VACANCY_GET_SUCCESS = 'VACANCY_GET_SUCCESS';
const VACANCY_GET_ERROR = 'VACANCY_GET_ERROR';
const VACANCY_RESET_THANK_YOU = 'VACANCY_RESET_THANK_YOU';

const VACANCY_HISTORY_GET_REQUEST = 'VACANCY_HISTORY_GET_REQUEST';
const VACANCY_HISTORY_GET_SUCCESS = 'VACANCY_HISTORY_GET_SUCCESS';
const VACANCY_HISTORY_GET_ERROR = 'VACANCY_HISTORY_GET_ERROR';

const VACANCY_FOR_FORM_GET_REQUEST = 'VACANCY_FOR_FORM_GET_REQUEST';
const VACANCY_FOR_FORM_GET_SUCCESS = 'VACANCY_FOR_FORM_GET_SUCCESS';
const VACANCY_FOR_FORM_GET_ERROR = 'VACANCY_FOR_FORM_GET_ERROR';

const VACANCY_GET_STATUSES = 'VACANCY_GET_STATUSES';
const VACANCY_GET_STATUSES_SUCCESS = 'VACANCY_GET_STATUSES_SUCCESS';
const VACANCY_GET_STATUSES_ERROR = 'VACANCY_GET_STATUSES_ERROR';

const VACANCY_GET_STATUSES_EXPORT = 'VACANCY_GET_STATUSES_EXPORT';
const VACANCY_GET_STATUSES_EXPORT_SUCCESS = 'VACANCY_GET_STATUSES_EXPORT_SUCCESS';
const VACANCY_GET_STATUSES_EXPORT_ERROR = 'VACANCY_GET_STATUSES_EXPORT_ERROR';

const VACANCY_ADDRESS_GET_REQUEST = 'VACANCY_ADDRESS_GET_REQUEST';
const VACANCY_ADDRESS_GET_SUCCESS = 'VACANCY_ADDRESS_GET_SUCCESS';
const VACANCY_ADDRESS_GET_SUCCESS_MULTIPLE = 'VACANCY_ADDRESS_GET_SUCCESS_MULTIPLE';
const VACANCY_ADDRESS_GET_ERROR = 'VACANCY_ADDRESS_GET_ERROR';
const VACANCY_ADDRESS_SELECT = 'VACANCY_ADDRESS_SELECT';
const VACANCY_ADDRESS_SELECT_NO_RESULT = 'VACANCY_ADDRESS_SELECT_NO_RESULT';

const initialState = {
  isSubmitting: false,
  hasError: false,
  isRequestingVacancyDetail: false,
  hasRequestingErrorVacancyDetail: false,
  isVacancySubscription: false,
  isVacancyUnsubscription: false,
  vacancyDetail: {},

  vacancyForForm: {},
  isRequestingVacancyForForm: false,
  hasRequestingVacancyForFormError: false,

  isRequestingVacancyHistory: false,
  hasRequestingErrorVacancyHistory: false,
  vacancyHistory: [],

  vacancyList: [],
  loadingVacancyList: false,
  loadingVacancyListError: false,
  loadingVacancyExport: false,
  loadingVacancyExportError: false,
  vacancyExport: null,
  vacancyGetStatusesInitialLoad: true,

  isRequestingVacancyAddress: false,
  hasRequestingErrorVacancyAddress: false,
  showAnyWay: false,
  showConnections: false,
  connections: {},
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case VACANCY_FORM_RESET:
      return {
        ...state,
        vacancyForForm: {},
      };

    case VACANCY_SUBMIT_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        hasError: false,
      };
    case VACANCY_SUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isVacancySubscription: true,
        isVacancyUnsubscription: false,
        hasError: false,
        vacancyDetail: payload,
        vacancyForForm: {},
      };
    case VACANCY_UNSUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isVacancySubscription: false,
        isVacancyUnsubscription: true,
        hasError: false,
        vacancyDetail: payload,
      };
    case VACANCY_SUBMIT_ERROR:
      return {
        ...state,
        isSubmitting: false,
        hasError: true,
      };

    case VACANCY_GET_REQUEST:
      return {
        ...state,
        isRequestingVacancyDetail: true,
        isVacancySubscription: false,
        isVacancyUnSubscription: false,
        hasRequestingErrorVacancyDetail: false,
      };
    case VACANCY_GET_SUCCESS:
      return {
        ...state,
        isRequestingVacancyDetail: false,
        hasRequestingErrorVacancyDetail: false,
        vacancyDetail: payload,
        vacancyHistory: [],
      };
    case VACANCY_GET_ERROR:
      return {
        ...state,
        isRequestingVacancyDetail: false,
        vacancyDetail: {},
        hasRequestingErrorVacancyDetail: true,
      };

    case VACANCY_RESET_THANK_YOU:
      return {
        ...state,
        isVacancySubscription: false,
        isVacancyUnsubscription: false,
      };

    case VACANCY_HISTORY_GET_REQUEST:
      return {
        ...state,
        isRequestingVacancyHistory: true,
        hasRequestingErrorVacancyHistory: false,
        vacancyHistory: [],
      };
    case VACANCY_HISTORY_GET_SUCCESS:
      return {
        ...state,
        isRequestingVacancyHistory: false,
        hasRequestingErrorVacancyHistory: false,
        vacancyHistory: payload,
      };
    case VACANCY_HISTORY_GET_ERROR:
      return {
        ...state,
        isRequestingVacancyHistory: false,
        hasRequestingErrorVacancyHistory: true,
        vacancyHistory: [],
      };

    case VACANCY_FOR_FORM_GET_REQUEST:
      return {
        ...state,
        isRequestingVacancyForForm: true,
        hasRequestingVacancyForFormError: false,
        vacancyForForm: {},
      };
    case VACANCY_FOR_FORM_GET_SUCCESS:
      return {
        ...state,
        isRequestingVacancyForForm: false,
        hasRequestingVacancyForFormError: false,
        vacancyForForm: payload,
      };
    case VACANCY_FOR_FORM_GET_ERROR:
      return {
        ...state,
        isRequestingVacancyForForm: false,
        hasRequestingVacancyForFormError: true,
        vacancyForForm: {},
      };

    case VACANCY_GET_STATUSES:
      return {
        ...state,
        loadingVacancyList: true,
        loadingVacancyListError: false,
      };
    case VACANCY_GET_STATUSES_SUCCESS:
      return {
        ...state,
        loadingVacancyList: false,
        loadingVacancyListError: false,
        vacancyList: payload,
        vacancyGetStatusesInitialLoad: false,
      };
    case VACANCY_GET_STATUSES_ERROR:
      return {
        ...state,
        loadingVacancyList: false,
        loadingVacancyListError: payload,
      };

    case VACANCY_GET_STATUSES_EXPORT:
      return {
        ...state,
        loadingVacancyExport: true,
        loadingVacancyExportError: false,
      };
    case VACANCY_GET_STATUSES_EXPORT_SUCCESS:
      return {
        ...state,
        loadingVacancyExport: false,
        loadingVacancyExportError: false,
        vacancyExport: payload,
      };
    case VACANCY_GET_STATUSES_EXPORT_ERROR:
      return {
        ...state,
        loadingVacancyExport: false,
        loadingVacancyExportError: payload,
      };

    case VACANCY_ADDRESS_GET_REQUEST:
      return {
        ...state,
        isRequestingVacancyAddress: true,
        hasRequestingErrorVacancyAddress: false,
        vacancyForForm: payload,
        showAnyWay: false,
        connections: {},
      };
    case VACANCY_ADDRESS_GET_SUCCESS:
      return {
        ...state,
        isRequestingVacancyAddress: false,
        hasRequestingErrorVacancyAddress: false,
        vacancyForForm: payload[Object.keys(payload)[0]],
        showAnyWay: false,
        showConnections: false,
        connections: {},
      };
    case VACANCY_ADDRESS_GET_SUCCESS_MULTIPLE:
      return {
        ...state,
        isRequestingVacancyAddress: false,
        hasRequestingErrorVacancyAddress: false,
        vacancyForForm: {},
        showAnyWay: false,
        showConnections: true,
        connections: payload,
      };
    case VACANCY_ADDRESS_GET_ERROR:
      return {
        ...state,
        isRequestingVacancyAddress: false,
        hasRequestingErrorVacancyAddress: true,
        showAnyWay: false,
        showConnections: false,
        connections: {},
      };
    case VACANCY_ADDRESS_SELECT:
      return {
        ...state,
        vacancyForForm: payload,
        showAnyWay: false,
        showConnections: false,
        connections: {},
      };
    case VACANCY_ADDRESS_SELECT_NO_RESULT:
      return {
        ...state,
        vacancyForForm: {},
        showAnyWay: true,
        showConnections: false,
        connections: {},
      };

    default:
      return state;
  }
};

/**
 * Clear redux form
 * This is helped by `createStore.js` because redux form isnt able to clear
 * itself all the time
 */
export const vacancyFormReset = createAction(VACANCY_FORM_RESET);
export const resetVacancyForm = () => (dispatch) => {
  dispatch(reset('vacancylogging'));
  dispatch(vacancyFormReset());
};

export const vacancySubmitRequest = createAction(VACANCY_SUBMIT_REQUEST);
export const vacancySubmitSuccess = createAction(VACANCY_SUBMIT_SUCCESS);
export const vacancySubmitError = createAction(VACANCY_SUBMIT_ERROR);

export const vacancySubscribe = () => (dispatch, getState) => {
  // Get the values from the state
  const formData = getState().form.vacancylogging;

  // Modify the values so that they can be passed to the API
  const apiViableData = mouldFormToApi(formData);

  // Notify the store that we are submitting
  dispatch(vacancySubmitRequest());

  // do the request
  return post({
    path: '/vacancy_status/subscribe',
    body: apiViableData,
  })
    .then((response) => {
      // if the response is a 400
      if (response['@type'] === 'ConstraintViolationList') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: response.status,
          statusText: response.statusText,
          body: response,
        });
      }

      dispatch(vacancySubmitSuccess(response));
      dispatch(vacancyFormReset());

      if (typeof window !== 'undefined') {
        const slug = stripString(response['@id'], '/leegstanden/leegstand');
        navigate(`${routes.vacancy.url}?vacancy=${slug}`);
      }

      return true;
    })
    .catch((err) => {
      const violations = getReadableViolationsFromResponse(err.body);

      const errorList = {
        _error: 'Het opslaan van de leegstand is mislukt, probeer het nog een keer.',
      };

      if (violations.mutation) {
        // eslint-disable-next-line dot-notation
        errorList['_error'] = violations.mutation;
      }

      dispatch(vacancySubmitError());
      throw new SubmissionError(errorList);
    });
};

export const vacancyUnsubmitSuccess = createAction(VACANCY_UNSUBMIT_SUCCESS);

export const vacancyUnsubscribe = () => (dispatch, getState) => {
  // Get the values from the state
  const formData = getState().form.vacancylogging;

  // Modify the values so that they can be passed to the API
  const apiViableData = mouldFormToApi(formData);

  // Notify the store that we are submitting
  dispatch(vacancySubmitRequest());

  // do the request
  return post({
    path: '/vacancy_status/unsubscribe',
    body: apiViableData,
  })
    .then((response) => {
      // if the response is a 400
      if (response['@type'] === 'ConstraintViolationList') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: response.status,
          statusText: response.statusText,
          body: response,
        });
      }

      dispatch(vacancyUnsubmitSuccess(response));
      dispatch(vacancyFormReset());

      if (typeof window !== 'undefined') {
        const slug = response['@id'].replace('/vacancy_statuses/', '');
        navigate(`${routes.vacancy.url}?vacancy=${slug}`);
      }

      return true;
    })
    .catch((err) => {
      const violations = getReadableViolationsFromResponse(err.body);

      const errorList = {
        _error: 'Het opslaan van de leegstand is mislukt, probeer het nog een keer.',
      };

      if (violations.mutation) {
        // eslint-disable-next-line dot-notation
        errorList['_error'] = violations.mutation;
      }

      dispatch(vacancySubmitError(err));
      throw new SubmissionError(errorList);
    });
};

/**
 * Get our vacancy for form
 */
export const vacancyForFormGetRequest = createAction(VACANCY_FOR_FORM_GET_REQUEST);
export const vacancyForFormSuccess = createAction(VACANCY_FOR_FORM_GET_SUCCESS);
export const vacancyForFormError = createAction(VACANCY_FOR_FORM_GET_ERROR);

export const fetchVacancyForForm = (id) => (dispatch) => {
  dispatch(vacancyForFormGetRequest());

  return get({
    path: `/vacancy_statuses/${id}`,
  })
    .then((response) => {
      if (response) {
        const formViableData = mouldApiToForm(response);

        dispatch(vacancyForFormSuccess(formViableData));
      }
    })
    .catch((err) => {
      dispatch(vacancyForFormError(err));
    });
};

/**
 * get our vacancy history
 */
export const vacancyHistoryGetRequest = createAction(VACANCY_HISTORY_GET_REQUEST);
export const vacancyHistoryGetSuccess = createAction(VACANCY_HISTORY_GET_SUCCESS);
export const vacancyHistoryGetError = createAction(VACANCY_HISTORY_GET_ERROR);

export const fetchVacancyHistory = (hash) => (dispatch) => {
  dispatch(vacancyHistoryGetRequest());

  return get({
    path: '/vacancy_statuses',
    query: {
      'address.hash': hash,
      'order[createdAt]': 'desc',
    },
  }).then((response) => {
    if (response['hydra:member']) {
      dispatch(vacancyHistoryGetSuccess(response['hydra:member']));
    }
  }).catch((err) => {
    dispatch(vacancyHistoryGetError(err));
  });
};

/**
 * get our vacancy thing
 */
export const vacancyGetRequest = createAction(VACANCY_GET_REQUEST);
export const vacancyGetSuccess = createAction(VACANCY_GET_SUCCESS);
export const vacancyGetError = createAction(VACANCY_GET_ERROR);
export const resetThankYou = createAction(VACANCY_RESET_THANK_YOU);

export const fetchVacancyDetail = (slug) => (dispatch, getState) => {
  const currentDetail = getState().vacancies.vacancyDetail;

  // if we've got a detailpage in state & its the same as the current slug, show it
  if (currentDetail['@id'] && currentDetail['@id'].indexOf(slug) > -1) {
    dispatch(vacancyGetSuccess(currentDetail));
    if (currentDetail.address.hash) {
      dispatch(fetchVacancyHistory(currentDetail.address.hash));
    }
    return true;
  }

  dispatch(vacancyGetRequest());

  return get({
    path: `/vacancy_statuses/${slug}`,
  })
    .then((response) => {
      if (response) {
        dispatch(vacancyGetSuccess(response));

        if (response.address.hash) {
          dispatch(fetchVacancyHistory(response.address.hash));
        }
      }
    })
    .catch((err) => {
      dispatch(vacancyGetError(err));
    });
};

/**
 * get vacancy statusses
 */
export const vacancyGetStatuses = createAction(VACANCY_GET_STATUSES);
export const vacancyGetStatusesSuccess = createAction(VACANCY_GET_STATUSES_SUCCESS);
export const vacancyGetStatusesError = createAction(VACANCY_GET_STATUSES_ERROR);

export const vacancyGetStatusesExport = createAction(VACANCY_GET_STATUSES_EXPORT);
export const vacancyGetStatusesExportSuccess = createAction(VACANCY_GET_STATUSES_EXPORT_SUCCESS);
export const vacancyGetStatusesExportError = createAction(VACANCY_GET_STATUSES_EXPORT_ERROR);

export const getVacancyList = (page, isExport) => (dispatch, getState) => {
  const state = getState();
  const { searchVacancyForm } = state.form;

  // Unable to use consts for every url parameter because backend can't handle serialized '.'s.
  // So every dotted parameter has to be a string. Every other const without '.'
  // is beneath this comment.
  let customer;
  let eanCode;
  let startDate;
  let endDate;
  let startDateUntil;
  let endDateUntil;
  let type;
  let subscribe;
  let unsubscribe;
  const group = 'advanced';

  if (searchVacancyForm && searchVacancyForm.values) {
    customer = searchVacancyForm.values.klant;
    // eslint-disable-next-line
    eanCode = searchVacancyForm.values.eanCode;
    startDate = searchVacancyForm.values.startdatum;
    startDateUntil = searchVacancyForm.values.startdatum_tm;
    endDate = searchVacancyForm.values.einddatum;
    endDateUntil = searchVacancyForm.values.einddatum_tm;
    subscribe = searchVacancyForm.values.aangemeld;
    unsubscribe = searchVacancyForm.values.afgemeld;

    if (startDate) {
      startDate = startDate.split('-').reverse().join('-');
    }

    if (endDate) {
      endDate = endDate.split('-').reverse().join('-');
    }

    if (startDateUntil) {
      startDateUntil = startDateUntil.split('-').reverse().join('-');
    }

    if (endDateUntil) {
      endDateUntil = endDateUntil.split('-').reverse().join('-');
    }

    if (subscribe && unsubscribe) {
      type = undefined;
    } else if (subscribe) {
      type = 'subscribe';
    } else if (unsubscribe) {
      type = 'unsubscribe';
    }

    if (startDate || startDateUntil) {
      startDate = {
        after: startDate,
        before: startDateUntil,
      };
    }

    if (endDate || endDateUntil) {
      endDate = {
        after: endDate,
        before: endDateUntil,
      };
    }
  }
  if (isExport) {
    dispatch(vacancyGetStatusesExport());
  } else {
    dispatch(vacancyGetStatuses());
  }

  return get({
    path: `/vacancy_statuses${isExport ? '/export' : ''}`,
    file: isExport,
    query: {
      page,
      customer,
      eanCode,
      'address.zipcode': (searchVacancyForm && searchVacancyForm.values)
      && searchVacancyForm.values.postcode,
      'address.houseNumber': (searchVacancyForm && searchVacancyForm.values)
      && searchVacancyForm.values.huisnummer,
      'address.houseNumberSuffix': (searchVacancyForm && searchVacancyForm.values)
      && searchVacancyForm.values.toevoeging,
      'address.city': (searchVacancyForm && searchVacancyForm.values)
      && searchVacancyForm.values.plaats,
      'address.street': (searchVacancyForm && searchVacancyForm.values)
      && searchVacancyForm.values.straat,
      startDate,
      endDate,
      type,
      group,
      delimiter: ',',
    },
  }).then((response) => {
    if (isExport) {
      dispatch(vacancyGetStatusesExportSuccess(response));
      saveAs(response, 'LeegstandExport.csv');
    } else {
      dispatch(vacancyGetStatusesSuccess(response));

      // redirect to results
      if (typeof window !== 'undefined' && !state.vacancies.vacancyGetStatusesInitialLoad) {
        // eslint-disable-next-line no-undef
        const resultElement = document.querySelector('#results');
        resultElement.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }).catch((err) => {
    if (isExport) {
      dispatch(vacancyGetStatusesExportError(err));
    } else {
      dispatch(vacancyGetStatusesError(err));
    }
  });
};

/**
 * get our vacancy address
 */
export const vacancyAddressGetRequest = createAction(VACANCY_ADDRESS_GET_REQUEST);
export const vacancyAddressGetSuccess = createAction(VACANCY_ADDRESS_GET_SUCCESS);
export const vacancyAddressGetSuccessMultiple = createAction(VACANCY_ADDRESS_GET_SUCCESS_MULTIPLE);
export const vacancyAddressGetError = createAction(VACANCY_ADDRESS_GET_ERROR);

export const fetchVacancyAddress = () => (dispatch, getState) => {
  const formData = getState().form.vacancylogging;
  // early return the mofo when the form or any of the fields are not present
  if (
    !formData
    || !formData.registeredFields['address.zipcode']
    || !formData.registeredFields['address.houseNumber']
    || !formData.registeredFields['address.houseNumberSuffix']
    || (
      !formData.values
      || !formData.values.address
      || !formData.values.address.zipcode
    )
    || (
      !formData.values
      || !formData.values.address
      || !formData.values.address.houseNumber
    )
    || (
      formData.syncErrors && formData.syncErrors.address && (
        formData.syncErrors.address.zipcode
        || formData.syncErrors.address.houseNumber
        || formData.syncErrors.address.houseNumberSuffix
      )
    )
  ) {
    return false;
  }

  dispatch(reset('vacancylogging'));

  const newformData = {
    customer: formData.values.customer ? formData.values.customer : null,
    address: {
      zipcode: formData.values.address.zipcode,
      houseNumber: formData.values.address.houseNumber,
      houseNumberSuffix: (formData.values.address.houseNumberSuffix && formData.values.address.houseNumberSuffix !== '') ? formData.values.address.houseNumberSuffix : null,
    },
    canMutate: true,
  };

  dispatch(vacancyAddressGetRequest(newformData));

  return get({
    path: '/connections',
    query: {
      zipcode: formData.values.address.zipcode,
      houseNumber: formData.values.address.houseNumber,
      houseNumberSuffix: (formData.values.address.houseNumberSuffix && formData.values.address.houseNumberSuffix !== '') ? formData.values.address.houseNumberSuffix : null,
    },
  }).then((response) => {
    if (response['hydra:member'] && response['hydra:member'].length) {
      const formDataFromConnection = convertConnectionToVacancyForm(response);

      const connectionKeys = Object.keys(formDataFromConnection);
      if (connectionKeys.length > 1) {
        dispatch(vacancyAddressGetSuccessMultiple(formDataFromConnection));
      } else {
        dispatch(vacancyAddressGetSuccess(formDataFromConnection));
      }
    } else {
      dispatch(vacancyAddressGetError());
    }
  }).catch((err) => {
    dispatch(vacancyAddressGetError(err));
  });
};

export const vacancyAddressSelect = createAction(VACANCY_ADDRESS_SELECT);
export const vacancyAddressSelectNoResult = createAction(VACANCY_ADDRESS_SELECT_NO_RESULT);

export const selectVacancyAddressFromConnection = (connection) => (dispatch, getState) => {
  const { connections } = getState().vacancies;

  const selectedConnection = connections[connection];

  if (selectedConnection) {
    return dispatch(vacancyAddressSelect(selectedConnection));
  }
  return dispatch(vacancyAddressSelectNoResult({}));
};
