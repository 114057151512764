export const routes = {
  /**
   * Generic
   */
  dashboard: {
    url: '/dashboard',
    name: 'Dashboard',
  },

  passwordforget: {
    url: '/wachtwoord-vergeten',
    name: 'Wachtwoord vergeten?',
  },

  callback: {
    url: '/callback',
    name: 'Inloggen als admin',
  },

  /**
   * Vacancies
   */
  vacancySearch: {
    url: '/leegstanden',
    name: 'Leegstand zoeken en afmelden',
  },
  vacancy: {
    url: '/leegstanden/leegstand',
    name: 'Leegstand',
  },

  vacancySubscribe: {
    url: '/leegstanden/aanmelden',
    name: 'Leegstand aanmelden',
  },

  vacancySubscribeStepTwo: {
    url: '/leegstanden/aanmelden/stap-twee',
    name: 'Leegstand aanmelden stap twee',
  },

  vacancyUnsubscribe: {
    url: '/leegstanden/afmelden',
    name: 'Leegstand afmelden',
  },

  vacancyUnsubscribeStepTwo: {
    url: '/leegstanden/afmelden/stap-twee',
    name: 'Leegstand afmelden stap twee',
  },

  /**
   * Admin
   */
  admin: {
    url: '/beheer',
    name: 'Beheer',
  },

  adminOrganisations: {
    url: '/beheer/organisaties',
    name: 'Beheer organisaties',
  },
  adminOrganisation: {
    url: '/beheer/organisaties/organisatie',
    name: 'Organisatie',
  },

  adminCustomers: {
    url: '/beheer/klanten',
    name: 'Beheer klanten',
  },
  adminCustomer: {
    url: '/beheer/klanten/klant',
    name: 'Klant',
  },
  adminCustomersExport: {
    url: '/customers/export_excel',
    name: 'Exporteer Klantenlijst',
  },

  adminUsers: {
    url: '/beheer/accounts',
    name: 'Beheer accounts',
  },
  adminUser: {
    url: '/beheer/accounts/account',
    name: 'Account',
  },
};

export default {
  routes,
};
