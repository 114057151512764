import { isObject, objectMergeDeep } from './objectHelpers';

/* eslint-disable max-len */

/**
 * cleanupFormValueState
 * Cleans up dirty fields from form state
 */
export const cleanupFormValueState = (values) => {
  // cache new value object, to keep the state clean and get rid of by-reference overrides
  const newValues = objectMergeDeep({}, values);

  // Small function to check if a string is a number or not
  const isNumber = (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0); // eslint-disable-line no-restricted-globals

  /**
   * Function to clean up strings
   * Everything comes in as a string, so we need to convert some values to booleans or floats
   */
  const cleanupStrings = (obj) => {
    const newObject = obj;
    const keys = Object.keys(newObject);
    keys.forEach((key) => {
      if (isObject(newObject[key])) {
        cleanupStrings(newObject[key]);
      } else {
        if (newObject[key] === 'true') {
          newObject[key] = true;
        }

        if (newObject[key] === 'false') {
          newObject[key] = false;
        }

        if (isNumber(newObject[key])) {
          newObject[key] = parseFloat(newObject[key]);
        }
      }
    });
  };

  // run the thing
  cleanupStrings(newValues);

  return newValues;
};

/**
 * converts form data to API acceptable format
 */
export const mouldFormToApi = (formData) => {
  const mergedObject = objectMergeDeep({}, formData.values);
  const cleanValueState = cleanupFormValueState(mergedObject);

  return cleanValueState;
};

export const mouldApiToForm = (apiData) => {
  const viableObject = objectMergeDeep({}, apiData);

  const cleanupObject = (obj) => {
    const newObject = obj;
    const keys = Object.keys(newObject);
    keys.forEach((key) => {
      if (isObject(newObject[key])) {
        cleanupObject(newObject[key]);
      } else {
        if (key.includes('@context')) {
          delete newObject[key];
        }

        if (key.includes('@id')) {
          delete newObject[key];
        }

        if (key.includes('@type')) {
          delete newObject[key];
        }

        if (key.includes('user') && !key.includes('username')) {
          delete newObject[key];
        }

        if (typeof newObject[key] === 'boolean') {
          if (newObject[key]) {
            newObject[key] = 'true';
          } else {
            newObject[key] = 'false';
          }
        }

        if (typeof newObject[key] === 'number') {
          newObject[key] = newObject[key].toString();
        }

        if (!newObject[key]) {
          delete newObject[key];
        }
      }
    });
  };

  cleanupObject(viableObject);

  return viableObject;
};

export default {
  mouldFormToApi,
  mouldApiToForm,
};
