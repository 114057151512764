/* eslint-disable no-undef */
/**
 * Wraps sessionstorage in a check for window.
 * @param {action} string - the sessionstorage action you want to perform
 * @param {key} string - the sessionstorage key you want to set/get/remove
 * @param {value} string - the value you want to store
 */
const sessionStorageHelper = (action = 'get', key = false, value = false) => {
  if (typeof window === 'undefined' || !action || !key) {
    return false;
  }

  switch (action) {
    case 'set':
      return window.sessionStorage.setItem(key, value);
    case 'get': {
      const item = window.sessionStorage.getItem(key);

      // if the key is json, parse json first
      // as per: https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try
      if (item && (/^[\],:{}\s]*$/.test(item.replace(/\\["\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, '')))) {
        return JSON.parse(item);
      }

      return item;
    }
    case 'remove':
      return window.sessionStorage.removeItem(key);
    default:
      return window.sessionStorage.getItem(key);
  }
};

export default sessionStorageHelper;
