import { navigate } from 'gatsby';
import { reset, SubmissionError } from 'redux-form';

import { mouldApiToForm, mouldFormToApi } from '../helpers/formHelpers';
import { routes } from '../helpers/routes';
import sessionStorageHelper from '../helpers/sessionStorageHelper';
import getTranslation from '../helpers/translationHelper';
import { apiFunctions, del, get } from '../services/api';
import createAction from '../services/createAction';
import { flashMessageAdd, flashMessageRemoveById } from './flashMessages';

// Users overview
const USERS_GET = 'USERS_GET';
const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS';
const USERS_GET_ERROR = 'USERS_GET_ERROR';

// Users for dropdowns
const USERS_GET_SIMPLE = 'USERS_GET_SIMPLE';
const USERS_GET_SIMPLE_SUCCESS = 'USERS_GET_SIMPLE_SUCCESS';
const USERS_GET_SIMPLE_ERROR = 'USERS_GET_SIMPLE_ERROR';

// Single user
const USER_GET = 'USER_GET';
const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
const USER_GET_ERROR = 'USER_GET_ERROR';
const USER_FORM_RESET = 'USER_FORM_RESET';

// Post user
const USER_POST = 'USER_POST';
const USER_POST_SUCCESS = 'USER_POST_SUCCESS';
const USER_POST_ERROR = 'USER_POST_ERROR';

// Delete user
const USER_DEL = 'USER_DEL';
const USER_DEL_SUCCESS = 'USER_DEL_SUCCESS';
const USER_DEL_ERROR = 'USER_DEL_ERROR';

const initialState = {
  // Users overview
  loadingUsers: false,
  loadingUsersError: false,
  users: [],

  // Simple users
  loadingUsersSimple: false,
  loadingUsersSimpleError: false,
  usersSimple: sessionStorageHelper('get', 'usersSimple'),

  // Single user
  loadingUser: false,
  loadingUserError: false,
  user: {},
  userForm: {},

  // Submit user
  submitUser: false,
  submitUserSuccess: false,
  submitUserError: false,

  // Delete user
  deleteUser: false,
  deleteUserSuccess: false,
  deleteUserError: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Customer overview
    case USERS_GET:
      return {
        ...state,
        loadingUsers: true,
        loadingUsersError: false,
      };

    case USERS_GET_SUCCESS:
      return {
        ...state,
        loadingUsers: false,
        loadingUsersError: false,
        users: payload,
      };

    case USERS_GET_ERROR:
      return {
        ...state,
        loadingUsers: false,
        loadingUsersError: true,
      };

    // Simple customers
    case USERS_GET_SIMPLE:
      return {
        ...state,
        loadingUsersSimple: true,
        loadingUsersSimpleError: false,
      };
    case USERS_GET_SIMPLE_SUCCESS:
      sessionStorageHelper('set', 'usersSimple', JSON.stringify(payload));
      return {
        ...state,
        loadingUsersSimple: false,
        loadingUsersSimpleError: false,
        usersSimple: payload,
      };
    case USERS_GET_SIMPLE_ERROR:
      return {
        ...state,
        loadingUsersSimple: false,
        loadingUsersSimpleError: true,
      };

    // Single customer
    case USER_GET:
      return {
        ...state,
        loadingUser: true,
        submitUserSuccess: false,
        loadingUserError: false,
      };

    case USER_GET_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        submitUserSuccess: false,
        loadingUserError: false,
        user: payload.response,
        userForm: payload.formData,
      };

    case USER_GET_ERROR:
      return {
        ...state,
        loadingUser: false,
        loadingUserError: true,
      };

    case USER_FORM_RESET:
      return {
        ...state,
        userForm: {},
      };

    // submit customer
    case USER_POST:
      return {
        ...state,
        submitUser: true,
        submitUserSuccess: false,
        submitUserError: false,
      };

    case USER_POST_SUCCESS:
      return {
        ...state,
        submitUser: false,
        submitUserSuccess: true,
        submitUserError: false,
        users: [],
        user: payload,
        userForm: {},
      };

    case USER_POST_ERROR:
      return {
        ...state,
        submitUser: false,
        submitUserSuccess: false,
        submitUserError: true,
      };

    // Delete customer
    case USER_DEL:
      return {
        ...state,
        deleteUser: true,
        deleteUserSuccess: false,
        deleteUserError: false,
      };

    case USER_DEL_SUCCESS:
      return {
        ...state,
        deleteUser: false,
        deleteUserSuccess: true,
        deleteUserError: false,
        users: payload,
      };

    case USER_DEL_ERROR:
      return {
        ...state,
        deleteUser: false,
        deleteUserSuccess: false,
        deleteUserError: true,
      };

    default:
      return state;
  }
};

/**
 * Get users
 */
export const usersGet = createAction(USERS_GET);
export const usersGetSuccess = createAction(USERS_GET_SUCCESS);
export const usersGetError = createAction(USERS_GET_ERROR);

export const fetchUsers = (page) => (dispatch, getState) => {
  const { searchUserForm } = getState().form;

  dispatch(usersGet());

  return get({
    path: '/users',
    query: {
      page,
      organization: (searchUserForm && searchUserForm.values)
        && searchUserForm.values.organization,
      username: (searchUserForm && searchUserForm.values)
        && searchUserForm.values.username,
    },
  }).then((response) => {
    dispatch(usersGetSuccess(response));
  }).catch((err) => {
    dispatch(usersGetError(err));
  });
};

/**
 * Get simple customers
 */
export const usersGetSimple = createAction(USERS_GET_SIMPLE);
export const usersGetSimpleSuccess = createAction(USERS_GET_SIMPLE_SUCCESS);
export const usersGetSimpleError = createAction(USERS_GET_SIMPLE_ERROR);

export const fetchUsersSimple = () => (dispatch) => {
  // notify the store that we are logging in
  dispatch(usersGetSimple());

  // do the request
  return get({
    path: '/users/simple',
  })
    .then((response) => {
      if (response['hydra:member']) {
        const users = [];
        response['hydra:member'].forEach((user) => {
          if (user['@id'] && user.username) {
            const userObject = {
              label: user.username,
              value: user['@id'],
              blocked: user.blocked,
            };
            users.push(userObject);
          }
        });
        dispatch(usersGetSimpleSuccess(users));
      }
    })
    .catch((err) => {
      dispatch(usersGetSimpleError(err));
    });
};

/**
 * Get single user
 */
export const userGet = createAction(USER_GET);
export const userGetSuccess = createAction(USER_GET_SUCCESS);
export const userGetError = createAction(USER_GET_ERROR);
export const resetUserForm = createAction(USER_FORM_RESET);

export const fetchUser = (slug) => (dispatch, getState) => {
  const currentUser = getState().users.user;

  // if we've got a detailpage in state & its the same as the current slug, show it
  if (currentUser['@id'] && currentUser['@id'].indexOf(slug) > -1) {
    const formData = mouldApiToForm(currentUser);

    const payload = {
      formData,
      response: currentUser,
    };
    dispatch(userGetSuccess(payload));

    dispatch(flashMessageRemoveById('saved-user'));
    dispatch(flashMessageRemoveById('removed-user'));
    return true;
  }

  dispatch(userGet());

  return get({
    path: `/users/${slug}`,
  }).then((response) => {
    const formData = mouldApiToForm(response);

    const payload = {
      response,
      formData,
    };

    dispatch(userGetSuccess(payload));

    dispatch(flashMessageRemoveById('saved-user'));
    dispatch(flashMessageRemoveById('removed-user'));
  }).catch((err) => {
    dispatch(userGetError(err));
  });
};

/**
 * Submit user
 */
export const userPost = createAction(USER_POST);
export const userPostSuccess = createAction(USER_POST_SUCCESS);
export const userPostError = createAction(USER_POST_ERROR);

export const submitUser = (event, type = 'post') => (dispatch, getState) => {
  event.preventDefault();

  const formData = getState().form.user;

  const apiViableData = mouldFormToApi(formData);

  const currentUser = getState().users.user;

  // Notify the store that we are submitting
  dispatch(userPost());

  const path = (type === 'put' && currentUser['@id']) ? currentUser['@id'] : '/users';

  return apiFunctions[type]({
    path,
    body: apiViableData,
  })
    .then((response) => {
      if (response.status >= 400 && response.status < 500) {
        dispatch(flashMessageAdd({
          id: 'saved-user',
          title: 'Opslaan mislukt',
          message: `Het opslaan van de gebruiker is mislukt: "${getTranslation(response['hydra:description'])}"`,
          type: 'error',
        }));
        return;
      }
      // Reset form
      dispatch(reset('user'));

      dispatch(flashMessageAdd({
        id: 'saved-user',
        title: 'Opslaan succesvol',
        message: `U heeft “${response.username}” succesvol opgeslagen`,
        type: 'success',
      }));

      dispatch(userPostSuccess(response));

      if (typeof window !== 'undefined') {
        navigate(routes.adminUsers.url);
      }
    })
    .catch((err) => {
      dispatch(userPostError(err));
      throw new SubmissionError({
        _error: 'Het opslaan van het account is mislukt, probeer het nog een keer.',
      });
    });
};

/**
 * Remove user
 */
export const userDel = createAction(USER_DEL);
export const userDelSuccess = createAction(USER_DEL_SUCCESS);
export const userDelError = createAction(USER_DEL_ERROR);

export const removeUser = (event) => (dispatch, getState) => {
  event.preventDefault();
  const currentUser = getState().users.user;
  const currentUsers = getState().users.users;

  if (typeof window !== 'undefined') {
    const name = window.prompt('Weet u zeker dat u dit account wilt verwijderen? Zo ja, vul hieronder de gebruikersnaam van het account in:'); // eslint-disable-line

    if (name && name !== '' && (name.toLowerCase() === currentUser.username.toLowerCase())) {
      dispatch(userDel);

      return del({
        path: currentUser['@id'],
        body: {
          name,
        },
      })
        .then(() => {
          // Notify the user
          dispatch(flashMessageAdd({
            id: 'removed-user',
            title: 'Verwijderen succesvol',
            message: `U heeft “${currentUser.username}” succesvol verwijderd`,
            type: 'success',
          }));

          // remove from customers in state
          if (currentUsers['hydra:member']) {
            currentUsers['hydra:member'].splice(currentUsers['hydra:member'].findIndex((e) => e['@id'] === currentUser['@id']), 1);
          }
          dispatch(userDelSuccess(currentUsers));

          // Navigate to customer overview
          if (typeof window !== 'undefined') {
            navigate(routes.adminUsers.url);
          }
        })
        .catch((err) => {
          dispatch(userPostError(err));
        });
    }

    window.alert('Het account is niet verwijderd. Zorg dat de ingevulde gebruikersnaam overeenkomt met de gebruikersnaam van het account.'); // eslint-disable-line
  }

  return false;
};
