/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'formdata-polyfill';
import 'whatwg-fetch';
import 'core-js/actual/object';
import 'core-js/actual/array';
import 'core-js/actual/promise';
import './src/fonts/fonts.css';
import './src/styles/styles.scss';

import wrapProvider from './wrap-provider';

/**
 * Wrap the gatsby root element with our provider
 */
// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = wrapProvider;
