import qs from 'qs';

import { getJwtAuthHeader } from '../helpers/jwtHelpers';

export const API_ENDPOINT = process.env.GATSBY_API_ENDPOINT || 'https://leegstandhulp.e-container.nl';

const isJson = (response) => {
  const contentType = response.headers.get('content-type');
  return (contentType && (contentType.indexOf('application/json') !== -1 || contentType.indexOf('application/ld+json') !== -1));
};

const request = ({
  path,
  options,
  file,
}) => new Promise((resolve, reject) => {
  fetch(path, options)
    .then(async (response) => {
      if (response.ok || (response.status >= 400 && response.status < 500)) {
        if (file) return response.blob();

        if (isJson(response)) {
          const res = await response.json();
          res.status = response.status;
          return res;
        }

        return response;
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
      });
    })
    .then((json) => {
      resolve(json);
    })
    .catch((error) => {
      reject(error);
    });
});

const generateOptions = ({
  method,
  path,
  query,
  body,
  file = false,
  includeJwt = true,
}) => {
  let headers;

  if (file) {
    headers = {
      'Content-Type': 'text/csv',
      Accept: 'text/csv',
    };
  } else {
    headers = {
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
    };
  }

  if (includeJwt) {
    headers = { ...headers, ...getJwtAuthHeader() };
  }

  return ({
    path: `${API_ENDPOINT}${path}${query ? '?' : ''}${qs.stringify(query || {})}`,
    options: {
      headers,
      method,
      ...(body ? { body: JSON.stringify(body) } : {}),
    },
    file,
  });
};

export const get = ({ path, query, file }) => request(generateOptions({
  method: 'GET', path, query, file,
}));
export const del = ({
  path, body, query, file,
}) => request(generateOptions({
  method: 'DELETE', path, body, query, file,
}));
export const post = ({
  path, body, file, includeJwt,
}) => request(generateOptions({
  method: 'POST', path, body, file, includeJwt,
}));
export const put = ({
  path, body, file, includeJwt,
}) => request(generateOptions({
  method: 'PUT', path, body, file, includeJwt,
}));

export const apiFunctions = {
  get,
  del,
  post,
  put,
};
