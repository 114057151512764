import { navigate } from 'gatsby';
import { reset, SubmissionError } from 'redux-form';

import { routes } from '../helpers/routes';
import sessionStorageHelper from '../helpers/sessionStorageHelper';
import { apiFunctions, del, get } from '../services/api';
import createAction from '../services/createAction';
import { flashMessageAdd } from './flashMessages';

// Organizations overview
const ORGANIZATIONS_GET = 'ORGANIZATIONS_GET';
const ORGANIZATIONS_GET_SUCCESS = 'ORGANIZATIONS_GET_SUCCESS';
const ORGANIZATIONS_GET_ERROR = 'ORGANIZATIONS_GET_ERROR';

// Organizations for dropdowns
const ORGANIZATIONS_GET_SIMPLE = 'ORGANIZATIONS_GET_SIMPLE';
const ORGANIZATIONS_GET_SIMPLE_SUCCESS = 'ORGANIZATIONS_GET_SIMPLE_SUCCESS';
const ORGANIZATIONS_GET_SIMPLE_ERROR = 'ORGANIZATIONS_GET_SIMPLE_ERROR';

// Single organization
const ORGANIZATION_GET = 'ORGANIZATION_GET';
const ORGANIZATION_GET_SUCCESS = 'ORGANIZATION_GET_SUCCESS';
const ORGANIZATION_GET_ERROR = 'ORGANIZATION_GET_ERROR';
const ORGANIZATION_FORM_RESET = 'ORGANIZATION_FORM_RESET';

// Post organization
const ORGANIZATION_POST = 'ORGANIZATION_POST';
const ORGANIZATION_POST_SUCCESS = 'ORGANIZATION_POST_SUCCESS';
const ORGANIZATION_POST_ERROR = 'ORGANIZATION_POST_ERROR';

// Delete organization
const ORGANIZATION_DEL = 'ORGANIZATION_DEL';
const ORGANIZATION_DEL_SUCCESS = 'ORGANIZATION_DEL_SUCCESS';
const ORGANIZATION_DEL_ERROR = 'ORGANIZATION_DEL_ERROR';

const initialState = {
  // Organization overview
  loadingOrganizations: false,
  loadingOrganizationsError: false,
  organizations: [],

  // Simple Organizations
  loadingOrganizationsSimple: false,
  loadingOrganizationsSimpleError: false,
  organizationsSimple: sessionStorageHelper('get', 'organizationsSimple'),

  // Single organization
  loadingOrganization: false,
  loadingOrganizationError: false,
  organization: {},

  // Submit organization
  submitOrganization: false,
  submitOrganizationSuccess: false,
  submitOrganizationError: false,

  // Delete organization
  deleteOrganization: false,
  deleteOrganizationSuccess: false,
  deleteOrganizationError: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Organization overview
    case ORGANIZATIONS_GET:
      return {
        ...state,
        loadingOrganizations: true,
        loadingOrganizationsError: false,
      };

    case ORGANIZATIONS_GET_SUCCESS:
      return {
        ...state,
        loadingOrganizations: false,
        loadingOrganizationsError: false,
        organizations: payload,
      };

    case ORGANIZATIONS_GET_ERROR:
      return {
        ...state,
        loadingOrganizations: false,
        loadingOrganizationsError: true,
      };

    // Simple customers
    case ORGANIZATIONS_GET_SIMPLE:
      return {
        ...state,
        loadingOrganizationsSimple: true,
        loadingOrganizationsSimpleError: false,
      };
    case ORGANIZATIONS_GET_SIMPLE_SUCCESS:
      sessionStorageHelper('set', 'organizationsSimple', JSON.stringify(payload));
      return {
        ...state,
        loadingOrganizationsSimple: false,
        loadingOrganizationsSimpleError: false,
        organizationsSimple: payload,
      };
    case ORGANIZATIONS_GET_SIMPLE_ERROR:
      return {
        ...state,
        loadingOrganizationsSimple: false,
        loadingOrganizationsSimpleError: true,
      };

    // Single organization
    case ORGANIZATION_GET:
      return {
        ...state,
        loadingOrganization: true,
        submitOrganizationSuccess: false,
        loadingOrganizationError: false,
      };

    case ORGANIZATION_GET_SUCCESS:
      return {
        ...state,
        loadingOrganization: false,
        submitOrganizationSuccess: false,
        loadingOrganizationError: false,
        organization: payload,
      };

    case ORGANIZATION_GET_ERROR:
      return {
        ...state,
        loadingOrganization: false,
        loadingOrganizationError: true,
      };

    case ORGANIZATION_FORM_RESET:
      return {
        ...state,
        organization: {},
      };

    // submit org
    case ORGANIZATION_POST:
      return {
        ...state,
        submitOrganization: true,
        submitOrganizationSuccess: false,
        submitOrganizationError: false,
      };

    case ORGANIZATION_POST_SUCCESS:
      return {
        ...state,
        submitOrganization: false,
        submitOrganizationSuccess: true,
        submitOrganizationError: false,
        organizations: [],
        organization: {},
      };

    case ORGANIZATION_POST_ERROR:
      return {
        ...state,
        submitOrganization: false,
        submitOrganizationSuccess: false,
        submitOrganizationError: true,
      };

    case ORGANIZATION_DEL:
      return {
        ...state,
        deleteOrganization: true,
        deleteOrganizationSuccess: false,
        deleteOrganizationError: false,
      };

    case ORGANIZATION_DEL_SUCCESS:
      return {
        ...state,
        deleteOrganization: false,
        deleteOrganizationSuccess: true,
        deleteOrganizationError: false,
        organizations: payload,
        organization: {},
      };

    case ORGANIZATION_DEL_ERROR:
      return {
        ...state,
        deleteOrganization: false,
        deleteOrganizationSuccess: false,
        deleteOrganizationError: true,
        organization: {},
      };

    default:
      return state;
  }
};

/**
 * Get organizations
 */
export const organizationsGet = createAction(ORGANIZATIONS_GET);
export const organizationsGetSuccess = createAction(ORGANIZATIONS_GET_SUCCESS);
export const organizationsGetError = createAction(ORGANIZATIONS_GET_ERROR);

export const fetchOrganizations = (page) => (dispatch, getState) => {
  const { searchOrganizationForm } = getState().form;

  dispatch(organizationsGet());

  return get({
    path: '/organizations',
    query: {
      page,
      name: (searchOrganizationForm && searchOrganizationForm.values)
        && searchOrganizationForm.values.name,
      relationNumber: (searchOrganizationForm && searchOrganizationForm.values)
        && searchOrganizationForm.values.relationNumber,
    },
  }).then((response) => {
    dispatch(organizationsGetSuccess(response));
  }).catch((err) => {
    dispatch(organizationsGetError(err));
  });
};

/**
 * Get simple organizations
 */
export const organizationsGetSimple = createAction(ORGANIZATIONS_GET_SIMPLE);
export const organizationsGetSimpleSuccess = createAction(ORGANIZATIONS_GET_SIMPLE_SUCCESS);
export const organizationsGetSimpleError = createAction(ORGANIZATIONS_GET_SIMPLE_ERROR);

export const fetchOrganizationsSimple = () => (dispatch) => {
  // notify the store that we are logging in
  dispatch(organizationsGetSimple());

  // do the request
  return get({
    path: '/organizations/simple',
  })
    .then((response) => {
      if (response['hydra:member']) {
        const organizations = [];
        response['hydra:member'].forEach((organization) => {
          if (organization['@id'] && organization.name) {
            const organizationObject = {
              label: organization.name,
              value: organization['@id'],
            };
            organizations.push(organizationObject);
          }
        });
        dispatch(organizationsGetSimpleSuccess(organizations));
      }
    })
    .catch((err) => {
      dispatch(organizationsGetSimpleError(err));
    });
};

/**
 * Get single organization
 */
export const organizationGet = createAction(ORGANIZATION_GET);
export const organizationGetSuccess = createAction(ORGANIZATION_GET_SUCCESS);
export const organizationGetError = createAction(ORGANIZATION_GET_ERROR);
export const resetOrganizationForm = createAction(ORGANIZATION_FORM_RESET);

export const fetchOrganization = (slug) => (dispatch, getState) => {
  const currentOrganization = getState().organizations.organization;

  // if we've got a detailpage in state & its the same as the current slug, show it
  if (currentOrganization['@id'] && currentOrganization['@id'].indexOf(slug) > -1) {
    dispatch(organizationGetSuccess(currentOrganization));
    return true;
  }

  dispatch(organizationGet());

  return get({
    path: `/organizations/${slug}`,
  }).then((response) => {
    dispatch(organizationGetSuccess(response));
  }).catch((err) => {
    dispatch(organizationGetError(err));
  });
};

/**
 * Submit organization
 */
export const organizationPost = createAction(ORGANIZATION_POST);
export const organizationPostSuccess = createAction(ORGANIZATION_POST_SUCCESS);
export const organizationPostError = createAction(ORGANIZATION_POST_ERROR);

export const submitOrganization = (event, type = 'post') => (dispatch, getState) => {
  event.preventDefault();

  const formData = getState().form.organization.values;

  const currentOrganization = getState().organizations.organization;

  // Notify the store that we are submitting
  dispatch(organizationPost());

  const path = (type === 'put' && currentOrganization['@id']) ? currentOrganization['@id'] : '/organizations';

  return apiFunctions[type]({
    path,
    body: formData,
  })
    .then((response) => {
      // reset form
      dispatch(reset('organization'));

      // Get new set of simple organizations
      dispatch(fetchOrganizationsSimple());

      dispatch(organizationPostSuccess(response));

      if (typeof window !== 'undefined') {
        navigate(routes.adminOrganisations.url);
      }

      dispatch(flashMessageAdd({
        id: 'saved-organization',
        title: 'Opslaan succesvol',
        message: `U heeft “${response.name}” succesvol opgeslagen`,
        type: 'success',
      }));
    })
    .catch((err) => {
      dispatch(organizationPostError(err));
      throw new SubmissionError({
        _error: 'Het opslaan van de organisatie is mislukt, probeer het nog een keer.',
      });
    });
};

/**
 * Remove organization
 */
export const organizationDel = createAction(ORGANIZATION_DEL);
export const organizationDelSuccess = createAction(ORGANIZATION_DEL_SUCCESS);
export const organizationDelError = createAction(ORGANIZATION_DEL_ERROR);

export const removeOrganization = (event) => (dispatch, getState) => {
  event.preventDefault();
  const currentOrganization = getState().organizations.organization;
  const currentOrganizations = getState().organizations.organizations;

  if (typeof window !== 'undefined') {
    const name = window.prompt('Weet u zeker dat u deze organisatie wilt verwijderen? Zo ja, vul hieronder de naam van de organisatie in:'); // eslint-disable-line

    if (name && name !== '' && (name.toLowerCase() === currentOrganization.name.toLowerCase())) {
      dispatch(organizationDel);

      return del({
        path: currentOrganization['@id'],
        body: {
          name,
        },
      })
        .then(() => {
          // reset form
          dispatch(reset('organization'));

          // Get new set of simple organizations
          dispatch(fetchOrganizationsSimple());

          // Notify the user
          dispatch(flashMessageAdd({
            id: 'removed-organization',
            title: 'Verwijderen succesvol',
            message: `U heeft “${currentOrganization.name}” succesvol verwijderd`,
            type: 'success',
          }));

          // remove from organizations in state
          if (currentOrganizations['hydra:member']) {
            currentOrganizations['hydra:member'].splice(currentOrganizations['hydra:member'].findIndex((e) => e['@id'] === currentOrganization['@id']), 1);
          }
          dispatch(organizationDelSuccess(currentOrganizations));

          // Navigate to organization overview
          if (typeof window !== 'undefined') {
            navigate(routes.adminOrganisations.url);
          }
        })
        .catch((err) => {
          dispatch(organizationPostError(err));
        });
    }

    window.alert('De organisatie is niet verwijderd. Zorg dat de ingevulde naam overeenkomt met de naam van de organisatie.'); // eslint-disable-line
  }

  return false;
};
