export const convertConnectionToVacancyForm = (connection) => {
  const connectionGroup = {};
  connection['hydra:member'].forEach((item) => {
    const groupName = `address${item.zipcode}${item.houseNumber}${item.houseNumberSuffix ? item.houseNumberSuffix : ''}`.replace(' ', '').replace('-', '').replace('_', '').toLowerCase();

    if (!connectionGroup[groupName]) {
      // Create entry
      connectionGroup[groupName] = {
        address: {
          zipcode: item.zipcode,
          houseNumber: item.houseNumber,
          houseNumberSuffix: (item.houseNumberSuffix) ? item.houseNumberSuffix : '',
          street: item.street,
          city: item.city,
        },
        productType: item.productType,
        canMutate: true,
        isPrefilledVacancy: false,
      };

      if (item.productType === 'E' && item.eanCode) {
        connectionGroup[groupName].electricityReading = {};
        connectionGroup[groupName].electricityReading.eanCode = item.eanCode;
      }
      if (item.productType === 'G' && item.eanCode) {
        connectionGroup[groupName].gasReading = {};
        connectionGroup[groupName].gasReading.eanCode = item.eanCode;
      }
    } else {
      // stuff exists, so we need to merge, sick.
      // add support of multiple eanCodes on the same address
      if (item.productType === 'E' && item.eanCode) {
        // No electricityReading present yet, add the first one
        if (!connectionGroup[groupName].electricityReading) {
          connectionGroup[groupName].electricityReading = {};
          connectionGroup[groupName].electricityReading.eanCode = item.eanCode;
        } else if (!connectionGroup[groupName].electricityReading.eanCodes) {
          // An electricityReading is already present,
          // make the eanCodes array and fill it. Also clear the eanCode value.
          connectionGroup[groupName].electricityReading.eanCodes = [
            connectionGroup[groupName].electricityReading.eanCode,
            item.eanCode,
          ];
          connectionGroup[groupName].electricityReading.eanCode = '';
        } else {
          // Add the eanCode to the already present eanCodes array
          connectionGroup[groupName].electricityReading.eanCodes = [
            ...connectionGroup[groupName].electricityReading.eanCodes,
            item.eanCode,
          ];
        }
      }
      if (item.productType === 'G' && item.eanCode) {
        if (!connectionGroup[groupName].gasReading) {
          connectionGroup[groupName].gasReading = {};
          connectionGroup[groupName].gasReading.eanCode = item.eanCode;
        } else if (!connectionGroup[groupName].gasReading.eanCodes) {
          connectionGroup[groupName].gasReading.eanCodes = [
            connectionGroup[groupName].gasReading.eanCode,
            item.eanCode,
          ];
          connectionGroup[groupName].gasReading.eanCode = '';
        } else {
          connectionGroup[groupName].gasReading.eanCodes = [
            ...connectionGroup[groupName].gasReading.eanCodes,
            item.eanCode,
          ];
        }
      }
      connectionGroup[groupName].productType = `${connectionGroup[groupName].productType}${item.productType}`;
    }

    // remove double productType entries, sort alphabetically
    connectionGroup[groupName].productType = Array.from(new Set(connectionGroup[groupName].productType.split(''))).sort().join('');
  });

  return connectionGroup;
};

export default {
  convertConnectionToVacancyForm,
};
