import jwtDecode from 'jwt-decode';

import sessionStorageHelper from './sessionStorageHelper';

/**
 * Returns request header object when a user/token exists
 * @return {object} authorisation header.
 */
export const getJwtAuthHeader = () => {
  const token = sessionStorageHelper('get', 'token');

  if (token) {
    return { Authorization: `Bearer ${token}` };
  }

  return {};
};

/**
 * Returns decoded jwt properties
 * @return {object} jwt properties.
 */
export const getJwtProperties = () => {
  const token = sessionStorageHelper('get', 'token');

  if (token) {
    return jwtDecode(token);
  }

  return {};
};

/**
 * Returns the status of the token
 * @return {bool} active or inactive token.
 */
export const hasActiveToken = () => {
  const { exp } = getJwtProperties();
  const currentTime = new Date().getTime() / 1000;
  return (exp > currentTime);
};

/**
 * Registers jwt token
 * @param {string} token - jwt token which we need to store
 */
export const registerJwtToken = (token) => {
  sessionStorageHelper('set', 'token', token);
};

/**
 * Un registers jwt token
 * @return {bool} status
 */
export const unRegisterJwtToken = () => {
  sessionStorageHelper('remove', 'token');
  return true;
};

/**
 * Get token
 * @return {string} token
 */
export const getJwtToken = () => sessionStorageHelper('get', 'token');

export default {
  getJwtAuthHeader,
  getJwtProperties,
  hasActiveToken,
  registerJwtToken,
  getJwtToken,
};
