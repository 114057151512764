export const getViolationsFromResponse = (response) => {
  const violationList = {};
  if (response.violations) {
    response.violations.forEach((violation) => {
      if (typeof violationList[violation.propertyPath] === 'undefined') {
        violationList[violation.propertyPath] = [];
      }
      violationList[violation.propertyPath].push(violation.message);
    });
  }
  return violationList;
};

export const getReadableViolationsFromResponse = (response) => {
  if (!response) {
    return false;
  }

  const violations = getViolationsFromResponse(response);

  if (typeof violations.address !== 'undefined') {
    violations.address.forEach((message) => {
      if (message.includes('one mutation')) {
        violations.mutation = 'Je kunt maar 1 mutatie doorvoeren per 24 uur. Probeer het later nog eens.';
      }
    });
  }

  return violations;
};

export default {
  getViolationsFromResponse,
  getReadableViolationsFromResponse,
};
