import { isObject, objectMergeDeep } from './objectHelpers';

/*
city: "place"
customer: "/customers/34788320-018f-4461-9704-c49a27c6757f"
electricityReadingOne: "200"
electricityReadingReturnOne: "200"
electricityReadingReturnTwo: "200"
electricityReadingTwo: "200"
estimateMeter: "false"
existingBuilding: "true"
gasReading: "200"
heatValueOne: "200"
heatValueTwo: "200"
houseNumber: "20"
houseNumberSuffix: "A"
metertype: "false"
notes: "kenmerk"
numberOfCounters: "4"
productType: "EGW"
residents: "false"
startDate: "23-01-2019"
street: "street"
trait: "kenmerk"
zipcode: "1234AB"
*/

/* eslint-disable max-len */

const APIReadyObject = {
  customer: '',
  smartReader: false,
  address: {
    city: '',
    houseNumber: 0, // int
    houseNumberSuffix: '',
    street: '',
    zipcode: '',
  },
  productType: '',
  existingBuilding: null,
  readingDate: null, // yyyy-MM-dd
  residents: null,
  startDate: null, // yyyy-MM-dd
  endDate: null, // yyyy-MM-dd
  electricityEstimate: false,
  gasEstimate: false,
  heatEstimate: false,
  electricityReading: {
    valueOne: null, // int
    valueTwo: null, // int
    returnValueOne: null, // int
    returnValueTwo: null, // int
    eanCode: null, // int
    multipleValues: false, // bool
    solarPanels: false,
  },
  gasReading: {
    valueOne: null, // int
    eanCode: null, // int
  },
  heatReading: {
    valueOne: null, // int
    valueTwo: null, // int
  },
  trait: '',
  notes: '',
  conditionsCheck: false,
};

/**
 * cleanupFormValueState
 * Cleans up dirty fields from form state
 */
export const cleanupFormValueState = (values) => {
  // cache new value object, to keep the state clean and get rid of by-reference overrides
  const newValues = objectMergeDeep({}, values);

  // Small function to check if a string is a number or not
  const isNumber = (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0); // eslint-disable-line no-restricted-globals

  /**
   * Function to clean up strings
   * Everything comes in as a string, so we need to convert some values to booleans or floats
   */
  const cleanupStrings = (obj) => {
    const newObject = obj;
    const keys = Object.keys(newObject);
    keys.forEach((key) => {
      if (isObject(newObject[key])) {
        cleanupStrings(newObject[key]);
      } else {
        if (newObject[key] === 'true') {
          newObject[key] = true;
        }

        if (newObject[key] === 'false') {
          newObject[key] = false;
        }

        if (isNumber(newObject[key]) && !(key.toLowerCase().includes('ean') || key === 'notes' || key === 'remark')) {
          newObject[key] = parseFloat(newObject[key]);
        }

        if ((key === 'existingBuilding' || key === 'residents') && newObject[key] === null) {
          delete newObject[key];
        }
      }
    });
    return newObject;
  };

  const cleanValues = cleanupStrings(newValues);
  return cleanValues;
};

/**
 * converts vacancy form data to API acceptable format
 */
export const mouldFormToApi = (formData) => {
  const mergedObject = objectMergeDeep(APIReadyObject, formData.values);
  // Somehow, somewere the houseNumberSuffix is persisted in the "APIReadyObject" but needs to be ommited if not present in form data
  if (!formData.values.address.houseNumberSuffix) {
    mergedObject.address.houseNumberSuffix = '';
  }
  const cleanValueState = cleanupFormValueState(mergedObject);

  /**
   * start with warming up the data for API calls
   */
  cleanValueState.smartReader = typeof cleanValueState.existingBuilding === 'boolean' && cleanValueState.existingBuilding === false ? true : cleanValueState.smartReader;

  // zipcode formatting
  cleanValueState.address.zipcode = cleanValueState.address.zipcode.replace(' ', '').toUpperCase();

  // force house number suffix to be a string
  if (cleanValueState.address.houseNumberSuffix !== null) {
    cleanValueState.address.houseNumberSuffix = `${cleanValueState.address.houseNumberSuffix}`;
  }

  const readingDate = new Date();
  cleanValueState.readingDate = (cleanValueState.existingBuilding) ? readingDate : null;

  /**
   * Electricity
   */
  const hasElectricity = (cleanValueState.productType.toUpperCase().indexOf('E') !== -1);
  cleanValueState.electricityEstimate = (cleanValueState.smartReader) ? null : !!(cleanValueState.estimateMeter);

  if (hasElectricity) {
    if (cleanValueState.existingBuilding) {
      // bestaande bouw
      if (cleanValueState.smartReader || cleanValueState.estimateMeter) {
        // uitlezen of schatten, ✌🏻 too easy ✌🏻
        cleanValueState.electricityReading = null;
      } else {
        // waardes doorpasen, ean clearen
        // cleanValueState.electricityReading.eanCode = null; // as per https://esites.atlassian.net/browse/ENE-885

        cleanValueState.electricityReading.multipleValues = (cleanValueState.electricityReading.valueAmount > 1);
        cleanValueState.electricityReading.solarPanels = (cleanValueState.electricityReading.valueAmount === 4);

        if (cleanValueState.electricityReading.valueAmount === 1) {
          cleanValueState.electricityReading.valueTwo = null;
          cleanValueState.electricityReading.returnValueOne = null;
          cleanValueState.electricityReading.returnValueTwo = null;
        }

        if (cleanValueState.electricityReading.valueAmount === 2) {
          cleanValueState.electricityReading.returnValueOne = null;
          cleanValueState.electricityReading.returnValueTwo = null;
        }

        // wanneer 4 moeten ze allemaal doorgegeven worden.
      }
    } else {
      // Anders zijn er EANS ingevuld, dus resetten van de rest
      cleanValueState.electricityReading.multipleValues = false;
      cleanValueState.electricityReading.solarPanels = false;
      cleanValueState.electricityReading.valueOne = null;
      cleanValueState.electricityReading.valueTwo = null;
      cleanValueState.electricityReading.returnValueOne = null;
      cleanValueState.electricityReading.returnValueTwo = null;
    }
  } else {
    cleanValueState.electricityReading = null;
  }

  /**
   * Gas
   */
  const hasGas = (cleanValueState.productType.toUpperCase().indexOf('G') !== -1);
  cleanValueState.gasEstimate = (cleanValueState.smartReader) ? null : !!(cleanValueState.estimateMeter);

  if (hasGas) {
    if (cleanValueState.existingBuilding) {
      // bestaande bouw
      if (cleanValueState.smartReader || cleanValueState.estimateMeter) {
        // uitlezen of schatten, ✌🏻 too easy ✌🏻
        cleanValueState.gasReading = null;
      } else {
        // waardes doorpasen, ean clearen
        // cleanValueState.gasReading.eanCode = null; // as per https://esites.atlassian.net/browse/ENE-885
      }
    } else {
      // Anders zijn er EANS ingevuld, dus resetten van de rest
      cleanValueState.gasReading.valueOne = null;
    }
  } else {
    cleanValueState.gasReading = null;
  }

  /**
   * Heat
   */
  const hasHeat = (cleanValueState.productType.toUpperCase().indexOf('W') !== -1);
  cleanValueState.heatEstimate = !!(cleanValueState.estimateMeter);

  if (hasHeat) {
    if (cleanValueState.estimateMeter || !cleanValueState.existingBuilding) {
      // schatten, ✌🏻 too easy ✌🏻
      cleanValueState.heatReading = null;
    }
    // anders beide velden invull0n
  } else {
    cleanValueState.heatReading = null;
  }

  if (cleanValueState.address.hash) {
    delete cleanValueState.address.hash;
  }

  return cleanValueState;
};

export const mouldApiToForm = (apiData) => {
  const viableObject = objectMergeDeep({}, apiData);

  viableObject.canMutate = true;
  // const today = new Date();
  // const createdAtDate = new Date(viableObject.createdAt);
  // const canMutateByTime = (Math.abs(today.getTime() - createdAtDate.getTime())) < 86400000;

  const cleanupObject = (obj) => {
    const newObject = obj;
    const keys = Object.keys(newObject);
    keys.forEach((key) => {
      if (key === 'customer') {
        newObject[key] = newObject[key]['@id'];
      } else if (isObject(newObject[key])) {
        cleanupObject(newObject[key]);
      } else {
        if (key.includes('@context')) {
          delete newObject[key];
        }

        if (key.includes('@id')) {
          delete newObject[key];
        }

        if (key.includes('@type')) {
          delete newObject[key];
        }

        if (key.includes('user')) {
          delete newObject[key];
        }

        // includes startDate so we're going to unsubscribe
        if (key.includes('startDate')) {
          // if (
          //   createdAtDate
          //   && canMutateByTime
          //   && newObject[key]
          // ) {
          //   newObject.canMutate = false;
          // }
          delete newObject[key];
        }

        // includes startDate so we're going to subsrcibe
        if (key.includes('endDate')) {
          // if (newObject[key]) {
          //   newObject.canMutate = false;
          // }
          delete newObject[key];
        }

        if (typeof newObject[key] === 'boolean' && key !== 'canMutate') {
          if (newObject[key]) {
            newObject[key] = 'true';
          } else {
            newObject[key] = 'false';
          }
        }

        if (typeof newObject[key] === 'number') {
          newObject[key] = newObject[key].toString();
        }

        if (!newObject[key]) {
          delete newObject[key];
        }
      }
    });
  };

  if (viableObject.electricityEstimate || viableObject.gasEstimate || viableObject.heatEstimate) {
    viableObject.estimateMeter = 'true';
  } else {
    viableObject.estimateMeter = 'false';
  }

  if (viableObject.electricityReading && viableObject.electricityReading.valueOne && !viableObject.electricityReading.valueTwo) {
    viableObject.electricityReading.valueAmount = '1';
  }

  if (viableObject.electricityReading && viableObject.electricityReading.valueTwo && !viableObject.electricityReading.returnValueOne) {
    viableObject.electricityReading.valueAmount = '2';
  }

  if (viableObject.electricityReading && viableObject.electricityReading.returnValueOne && viableObject.electricityReading.returnValueTwo) {
    viableObject.electricityReading.valueAmount = '4';
  }

  cleanupObject(viableObject);

  viableObject.isPrefilledVacancy = true;

  return viableObject;
};

export default {
  mouldFormToApi,
  mouldApiToForm,
};
