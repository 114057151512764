import { navigate } from 'gatsby';
import { reset, SubmissionError } from 'redux-form';

import { mouldApiToForm, mouldFormToApi } from '../helpers/formHelpers';
import { routes } from '../helpers/routes';
import sessionStorageHelper from '../helpers/sessionStorageHelper';
import { apiFunctions, del, get } from '../services/api';
import createAction from '../services/createAction';
import { flashMessageAdd, flashMessageRemoveById } from './flashMessages';

// Customer overview
const CUSTOMERS_GET = 'CUSTOMERS_GET';
const CUSTOMERS_GET_SUCCESS = 'CUSTOMERS_GET_SUCCESS';
const CUSTOMERS_GET_ERROR = 'CUSTOMERS_GET_ERROR';

// Customer for dropdowns
const CUSTOMERS_GET_SIMPLE = 'CUSTOMERS_GET_SIMPLE';
const CUSTOMERS_GET_SIMPLE_SUCCESS = 'CUSTOMERS_GET_SIMPLE_SUCCESS';
const CUSTOMERS_GET_SIMPLE_ERROR = 'CUSTOMERS_GET_SIMPLE_ERROR';

// Single customer
const CUSTOMER_GET = 'CUSTOMER_GET';
const CUSTOMER_GET_SUCCESS = 'CUSTOMER_GET_SUCCESS';
const CUSTOMER_GET_ERROR = 'CUSTOMER_GET_ERROR';
const CUSTOMER_FORM_RESET = 'CUSTOMER_FORM_RESET';

// Post customer
const CUSTOMER_POST = 'CUSTOMER_POST';
const CUSTOMER_POST_SUCCESS = 'CUSTOMER_POST_SUCCESS';
const CUSTOMER_POST_ERROR = 'CUSTOMER_POST_ERROR';

// Delete customer
const CUSTOMER_DEL = 'CUSTOMER_DEL';
const CUSTOMER_DEL_SUCCESS = 'CUSTOMER_DEL_SUCCESS';
const CUSTOMER_DEL_ERROR = 'CUSTOMER_DEL_ERROR';

const initialState = {
  // Customer overview
  loadingCustomers: false,
  loadingCustomersError: false,
  customers: [],

  // Simple customers
  loadingCustomersSimple: false,
  loadingCustomersSimpleError: false,
  customersSimple: sessionStorageHelper('get', 'customersSimple'),

  // Single customer
  loadingCustomer: false,
  loadingCustomerError: false,
  customer: {},
  customerForm: {},

  // Submit customer
  submitCustomer: false,
  submitCustomerSuccess: false,
  submitCustomerError: false,

  // Delete customer
  deleteCustomer: false,
  deleteCustomerSuccess: false,
  deleteCustomerError: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    // Customer overview
    case CUSTOMERS_GET:
      return {
        ...state,
        loadingCustomers: true,
        loadingCustomersError: false,
      };

    case CUSTOMERS_GET_SUCCESS:
      return {
        ...state,
        loadingCustomers: false,
        loadingCustomersError: false,
        customers: payload,
      };

    case CUSTOMERS_GET_ERROR:
      return {
        ...state,
        loadingCustomers: false,
        loadingCustomersError: true,
      };

    // Simple customers
    case CUSTOMERS_GET_SIMPLE:
      return {
        ...state,
        loadingCustomersSimple: true,
        loadingCustomersSimpleError: false,
      };
    case CUSTOMERS_GET_SIMPLE_SUCCESS:
      sessionStorageHelper('set', 'customersSimple', JSON.stringify(payload));
      return {
        ...state,
        loadingCustomersSimple: false,
        loadingCustomersSimpleError: false,
        customersSimple: payload,
      };
    case CUSTOMERS_GET_SIMPLE_ERROR:
      return {
        ...state,
        loadingCustomersSimple: false,
        loadingCustomersSimpleError: true,
      };

    // Single customer
    case CUSTOMER_GET:
      return {
        ...state,
        loadingCustomer: true,
        submitCustomerSuccess: false,
        loadingCustomerError: false,
      };

    case CUSTOMER_GET_SUCCESS:
      return {
        ...state,
        loadingCustomer: false,
        submitCustomerSuccess: false,
        loadingCustomerError: false,
        customer: payload.response,
        customerForm: payload.formData,
      };

    case CUSTOMER_GET_ERROR:
      return {
        ...state,
        loadingCustomer: false,
        loadingCustomerError: true,
      };

    case CUSTOMER_FORM_RESET:
      return {
        ...state,
        customerForm: {},
      };

    // submit customer
    case CUSTOMER_POST:
      return {
        ...state,
        submitCustomer: true,
        submitCustomerSuccess: false,
        submitCustomerError: false,
      };

    case CUSTOMER_POST_SUCCESS:
      return {
        ...state,
        submitCustomer: false,
        submitCustomerSuccess: true,
        submitCustomerError: false,
        customers: [],
        customer: payload,
        customerForm: {},
      };

    case CUSTOMER_POST_ERROR:
      return {
        ...state,
        submitCustomer: false,
        submitCustomerSuccess: false,
        submitCustomerError: true,
      };

    // Delete customer
    case CUSTOMER_DEL:
      return {
        ...state,
        deleteCustomer: true,
        deleteCustomerSuccess: false,
        deleteCustomerError: false,
      };

    case CUSTOMER_DEL_SUCCESS:
      return {
        ...state,
        deleteCustomer: false,
        deleteCustomerSuccess: true,
        deleteCustomerError: false,
        customers: payload,
      };

    case CUSTOMER_DEL_ERROR:
      return {
        ...state,
        deleteCustomer: false,
        deleteCustomerSuccess: false,
        deleteCustomerError: true,
      };

    default:
      return state;
  }
};

/**
 * Get customers
 */
export const customersGet = createAction(CUSTOMERS_GET);
export const customersGetSuccess = createAction(CUSTOMERS_GET_SUCCESS);
export const customersGetError = createAction(CUSTOMERS_GET_ERROR);

export const fetchCustomers = (page) => (dispatch, getState) => {
  const { searchCustomerForm } = getState().form;

  dispatch(customersGet());

  return get({
    path: '/customers',
    query: {
      page,
      organization: (searchCustomerForm && searchCustomerForm.values)
        && searchCustomerForm.values.organization,
      name: (searchCustomerForm && searchCustomerForm.values)
        && searchCustomerForm.values.name,
      enecoNumber: (searchCustomerForm && searchCustomerForm.values)
        && searchCustomerForm.values.enecoNumber,
    },
  }).then((response) => {
    dispatch(customersGetSuccess(response));
  }).catch((err) => {
    dispatch(customersGetError(err));
  });
};

/**
 * Get simple customers
 */
export const customersGetSimple = createAction(CUSTOMERS_GET_SIMPLE);
export const customersGetSimpleSuccess = createAction(CUSTOMERS_GET_SIMPLE_SUCCESS);
export const customersGetSimpleError = createAction(CUSTOMERS_GET_SIMPLE_ERROR);

export const fetchCustomersSimple = () => (dispatch) => {
  // notify the store that we are logging in
  dispatch(customersGetSimple());

  // do the request
  return get({
    path: '/customers/simple',
  })
    .then((response) => {
      if (response['hydra:member']) {
        const customers = [];
        response['hydra:member'].forEach((customer) => {
          if (customer['@id'] && customer.name) {
            const customerObject = {
              label: customer.name,
              value: customer['@id'],
              blocked: customer.blocked,
            };
            customers.push(customerObject);
          }
        });
        dispatch(customersGetSimpleSuccess(customers));
      }
    })
    .catch((err) => {
      dispatch(customersGetSimpleError(err));
    });
};

/**
 * Get single customer
 */
export const customerGet = createAction(CUSTOMER_GET);
export const customerGetSuccess = createAction(CUSTOMER_GET_SUCCESS);
export const customerGetError = createAction(CUSTOMER_GET_ERROR);
export const resetCustomerForm = createAction(CUSTOMER_FORM_RESET);

export const fetchCustomer = (slug) => (dispatch, getState) => {
  const currentCustomer = getState().customers.customer;

  // if we've got a detailpage in state & its the same as the current slug, show it
  if (currentCustomer['@id'] && currentCustomer['@id'].indexOf(slug) > -1) {
    const formData = mouldApiToForm(currentCustomer);

    const payload = {
      formData,
      response: currentCustomer,
    };
    dispatch(customerGetSuccess(payload));

    dispatch(flashMessageRemoveById('saved-customer'));
    dispatch(flashMessageRemoveById('removed-customer'));
    return true;
  }

  dispatch(customerGet());

  return get({
    path: `/customers/${slug}`,
  }).then((response) => {
    const formData = mouldApiToForm(response);

    const payload = {
      response,
      formData,
    };

    dispatch(customerGetSuccess(payload));

    dispatch(flashMessageRemoveById('saved-customer'));
    dispatch(flashMessageRemoveById('removed-customer'));
  }).catch((err) => {
    dispatch(customerGetError(err));
  });
};

/**
 * Submit customer
 */
export const customerPost = createAction(CUSTOMER_POST);
export const customerPostSuccess = createAction(CUSTOMER_POST_SUCCESS);
export const customerPostError = createAction(CUSTOMER_POST_ERROR);

export const submitCustomer = (event, type = 'post') => (dispatch, getState) => {
  event.preventDefault();

  const formData = getState().form.customer;

  const apiViableData = mouldFormToApi(formData);

  if (!apiViableData.accumulated) {
    delete apiViableData.collectiveCustomerNumber;
    delete apiViableData.collectiveCustomerSequenceNumber;
  }

  const currentCustomer = getState().customers.customer;

  // Notify the store that we are submitting
  dispatch(customerPost());

  const path = (type === 'put' && currentCustomer['@id']) ? currentCustomer['@id'] : '/customers';

  return apiFunctions[type]({
    path,
    body: apiViableData,
  })
    .then((response) => {
      // rest form
      dispatch(reset('customer'));

      // Update set of customers
      dispatch(fetchCustomersSimple());

      dispatch(flashMessageAdd({
        id: 'saved-customer',
        title: 'Opslaan succesvol',
        message: `U heeft “${response.name}” succesvol opgeslagen`,
        type: 'success',
      }));

      dispatch(customerPostSuccess(response));

      if (typeof window !== 'undefined') {
        navigate(routes.adminCustomers.url);
      }
    })
    .catch((err) => {
      dispatch(customerPostError(err));
      throw new SubmissionError({
        _error: 'Het opslaan van de klant is mislukt, probeer het nog een keer.',
      });
    });
};

/**
 * Remove customer
 */
export const customerDel = createAction(CUSTOMER_DEL);
export const customerDelSuccess = createAction(CUSTOMER_DEL_SUCCESS);
export const customerDelError = createAction(CUSTOMER_DEL_ERROR);

export const removeCustomer = (event) => (dispatch, getState) => {
  event.preventDefault();
  const currentCustomer = getState().customers.customer;
  const currentCustomers = getState().customers.customers;

  if (typeof window !== 'undefined') {
    const name = window.prompt('Weet u zeker dat u deze klant wilt verwijderen? Zo ja, vul hieronder de naam van de klant in:'); // eslint-disable-line

    if (name && name !== '' && (name.toLowerCase() === currentCustomer.name.toLowerCase())) {
      dispatch(customerDel);

      return del({
        path: currentCustomer['@id'],
        body: {
          name,
        },
      })
        .then(() => {
          // Notify the user
          dispatch(flashMessageAdd({
            id: 'removed-customer',
            title: 'Verwijderen succesvol',
            message: `U heeft “${currentCustomer.name}” succesvol verwijderd`,
            type: 'success',
          }));

          // remove from customers in state
          if (currentCustomers['hydra:member']) {
            currentCustomers['hydra:member'].splice(currentCustomers['hydra:member'].findIndex((e) => e['@id'] === currentCustomer['@id']), 1);
          }
          dispatch(customerDelSuccess(currentCustomers));

          // Navigate to customer overview
          if (typeof window !== 'undefined') {
            navigate(routes.adminCustomers.url);
          }
        })
        .catch((err) => {
          dispatch(customerPostError(err));
        });
    }

    window.alert('De klant is niet verwijderd. Zorg dat de ingevulde naam overeenkomt met de naam van de klant.'); // eslint-disable-line
  }

  return false;
};
