import sessionStorageHelper from '../helpers/sessionStorageHelper';
import createAction from '../services/createAction';

// get all messages from localStorage
const getStoredFlashMessages = () => sessionStorageHelper('get', 'flashMessages');

// add flash message
const addFlashMessage = (flashMessageObject) => {
  const currentMessages = sessionStorageHelper('get', 'flashMessages');
  const newMessage = flashMessageObject;
  newMessage.showcount = 2;
  const existingMessage = currentMessages.find((message) => message.id === newMessage.id);
  if (!existingMessage) {
    const mergedMessages = (currentMessages) ? [...currentMessages, newMessage] : [newMessage];
    sessionStorageHelper('set', 'flashMessages', JSON.stringify(mergedMessages));
  }
};

// remove flash message
const removeFlashMessageById = (flashMessageId) => {
  const currentMessages = sessionStorageHelper('get', 'flashMessages');
  const filteredMessages = currentMessages
    ? currentMessages.filter((msg) => msg.id && msg.id !== flashMessageId)
    : [];

  sessionStorageHelper('set', 'flashMessages', JSON.stringify(filteredMessages));
};

// remove flash message
const removeFlashMessageAllButId = (flashMessageId) => {
  const currentMessages = sessionStorageHelper('get', 'flashMessages');

  const filteredMessages = currentMessages
    ? currentMessages.filter((msg) => msg.id && msg.id === flashMessageId)
    : [];

  sessionStorageHelper('set', 'flashMessages', JSON.stringify(filteredMessages));
};

const removeFlashMessageAllViewCountButId = (flashMessageId) => {
  const currentMessages = sessionStorageHelper('get', 'flashMessages') ? sessionStorageHelper('get', 'flashMessages') : [];

  const filteredMessages = currentMessages
    ? currentMessages.filter((msg) => msg.id && msg.id === flashMessageId)
    : [];

  const newMessages = [];

  currentMessages.forEach((message) => {
    message.showcount = message.showcount - 1; // eslint-disable-line

    if (message.showcount > 0) {
      newMessages.push(message);
    }
  });

  sessionStorageHelper('set', 'flashMessages', JSON.stringify(filteredMessages.concat(newMessages)));
};

const FLASH_MESSAGE_ADD = 'FLASH_MESSAGE_ADD';
const FLASH_MESSAGE_REMOVE_BY_ID = 'FLASH_MESSAGE_REMOVE';
const FLASH_MESSAGE_REMOVE_ALL_BUT_ID = 'FLASH_MESSAGE_REMOVE_ALL_BUT_ID';
const FLASH_MESSAGE_REMOVE_ALL_VIEW_COUNT_BUT_ID = 'FLASH_MESSAGE_REMOVE_ALL_VIEW_COUNT_BUT_ID';

export const internalFlashMessageAdd = createAction(FLASH_MESSAGE_ADD);
export const internalFlashMessageRemoveById = createAction(FLASH_MESSAGE_REMOVE_BY_ID);
export const internalFlashMessageRemoveAllButId = createAction(FLASH_MESSAGE_REMOVE_ALL_BUT_ID);
export const internalFlashMessageRemoveAllViewCountButId = createAction(
  FLASH_MESSAGE_REMOVE_ALL_VIEW_COUNT_BUT_ID,
);

const initState = {
  messages: getStoredFlashMessages(),
};

// eslint-disable-next-line default-param-last
export default (state = initState, { type, payload }) => {
  switch (type) {
    case FLASH_MESSAGE_ADD:
      addFlashMessage(payload);
      break;

    case FLASH_MESSAGE_REMOVE_BY_ID:
      removeFlashMessageById(payload);
      break;

    case FLASH_MESSAGE_REMOVE_ALL_BUT_ID:
      removeFlashMessageAllButId(payload);
      break;

    case FLASH_MESSAGE_REMOVE_ALL_VIEW_COUNT_BUT_ID:
      removeFlashMessageAllViewCountButId(payload);
      break;

    default:
      return state;
  }

  return {
    ...state,
    messages: getStoredFlashMessages(),
  };
};

// public functions
export const flashMessageAdd = (flashMessageObject) => (dispatch) => {
  dispatch(internalFlashMessageAdd(flashMessageObject));
};

export const flashMessageRemoveById = (flashMessageId) => (dispatch) => {
  dispatch(internalFlashMessageRemoveById(flashMessageId));
};

export const flashMessageRemoveAllButId = (flashMessageId) => (dispatch) => {
  dispatch(internalFlashMessageRemoveAllButId(flashMessageId));
};

export const flashMessageRemoveAllViewCountButId = (flashMessageId) => (dispatch) => {
  dispatch(internalFlashMessageRemoveAllViewCountButId(flashMessageId));
};
